import React from "react"
import MainLayout from "../components/mainLayout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import record from '../images/record.jpg'
import style from "./404.module.scss"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="404: Not found" />
    <Banner primaryText={'Oops'} backgroundImg={record}/>
    <div className={style.notFoundBody}>
      <p>That's a 404... we couldn't find what you're looking for. Sorry about that.</p>
    </div>
  </MainLayout>
)

export default NotFoundPage
